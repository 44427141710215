import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  MdOutlineViewQuilt,
  MdComputer,
  MdOutlinePersonSearch,
  MdOutlineCode,
  MdOutlineAnalytics,
  MdOutlinePinch,
} from "react-icons/md";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={6} className="home-about-description">
            <h1 style={{ fontSize: "2.5em" }}>
              HI! I'm <span className="purple"> Robin </span> a UX Designer and Software Developer
            </h1>
            <p className="home-about-body">
            With degrees and experience in both the design, frontend and backend of development, 
            it gives me valuable insights into all aspects of implementing a design. Allowing me 
            to better estimate the timeframes and capabilities of both the design- and development process. 
            </p>
          </Col>
          <Col md={6} className="skillButtons">
            <button className="button-19" disabled> <MdOutlineViewQuilt size={23}/> &nbsp;<b>UX Design</b></button>
            <button className="button-19" disabled> <MdOutlinePersonSearch size={23}/> &nbsp;<b>User Research</b></button>
            <button className="button-19" disabled> <MdComputer size={23}/> &nbsp;<b>Frontend</b></button>
            <button className="button-19" disabled> <MdOutlinePinch size={23}/> &nbsp;<b>User Testing</b></button>
            <button className="button-19" disabled> <MdOutlineCode size={23}/> &nbsp;<b>Backend</b></button>
            <button className="button-19" disabled> <MdOutlineAnalytics size={23}/> &nbsp;<b>Data Analysis</b></button>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
